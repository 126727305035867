<template>
  <div class="winner" v-if="config">
    <div class="blue-text" v-if="config.index_show_2.value">
<!--      <p>财商少年是一家以财商教育为基础的平台公司，</p>-->
<!--      <p>通过财智大赢家系统学习，助力家庭财商教育成长。</p>-->
<!--      <p>掌握财商智慧，成就美好未来。</p>-->
      <p>{{config.index_text_2_1.value}}</p>
      <p>{{config.index_text_2_2.value}}</p>
      <p>{{config.index_text_2_3.value}}</p>
    </div>
<!--    <title-comp title-cn="财智大赢家系统" title-en="Super FQ Winner System"></title-comp>-->

    <title-comp :title-cn="config.index_text_3_1.value" :title-en="config.index_text_3_2.value" v-if="config.index_show_3.value"></title-comp>

    <div class="normal-text" v-if="config.index_show_3.value">
<!--      <p>财智大赢家系统是由资深的导师专家团队，结合多年的课程研发、教学实践和机构合作经验打造而成。</p>-->
<!--      <p>致力于将财商少年打造成行业标准制定者，让孩子从小培养财商思维，养成良好习惯。</p>-->
      <p>{{config.index_text_3_3.value}}</p>
      <p>{{config.index_text_3_4.value}}</p>
    </div>

<!--    <img class="img" src="../../../assets/img/main/winner.png" alt="财智大赢家系统">-->

    <img class="img" :src="'https://oa.fqchildren.com/apis' + config.index_img_3_1.value[0].imgUrl" v-if="config.index_show_3.value">
  </div>
</template>

<script>
  import TitleComp from "./TitleComp";
  import dynamicConfigMixin from "@/mixins/dynamicConfigMixin";

  export default {
    name: "MainWinner",
    components: {
      TitleComp
    },
    mixins: [dynamicConfigMixin],
  }
</script>

<style lang="scss" scoped>
.blue-text {
  text-align: center;
  font-size: 30px;
  color: #0068b6;
  margin-top: 60px;
  line-height: 60px;
}

.title-comp {
  margin-top: 100px;
}
.normal-text {
  text-align: center;
  font-size: 20px;
  margin: 60px 0;
}
.img {
  display: block;
  width: 1280px;
  height: 400px;
  margin: 0 auto 100px;
}
</style>