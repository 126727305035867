<template>
  <div class="grow-up" v-if="config && config.index_show_5.value">
<!--    <section class="section">-->
<!--      <h2>财商少年品牌成长历程</h2>-->
<!--      <p>大手牵小手，未来一起走，普及家庭财商教育，是每个乐享FP的使命。</p>-->
<!--      <p>从2006年开始，财商教育就是我们事业发展的使命，专业技能的不断积累与提升就是我们事业的基石</p>-->
<!--      <p>我们坚信财商教育的意义非凡，责任重大。</p>-->
<!--      <p>少年强则家庭强，少年强则中国强！</p>-->
<!--      <p>我们需要与你们一起来，为中国财商普及而奋斗。</p>-->
<!--      <ol>-->
<!--        <li>2006年，开启体验式财商游戏教育</li>-->
<!--        <li>2007年，体验式财商游戏体系化</li>-->
<!--        <li>2008年，财商教育推广项目在全国起航</li>-->
<!--        <li>2009年，财商教育进万家</li>-->
<!--        <li>2010年，独获ISO9001服务流程质量认证</li>-->
<!--        <li>2011年，参加全国理财师大赛总决赛</li>-->
<!--        <li>2012年，财商学院成立，培养人才</li>-->
<!--        <li>2013年，荣获中国理财管理行业“突出贡献奖”</li>-->
<!--        <li>2014年，少儿财商教育进学校</li>-->
<!--        <li>2015年，少儿财商教育系列课程标准化，启动“财商少年”品牌计划</li>-->
<!--        <li>2016年，创办首届“财商少年”全国大赛，创立“未来CEO”课程体系</li>-->
<!--        <li>2017年，第二届“财商少年全国大赛”</li>-->
<!--        <li>2018年，第三届“财商少年全国大赛-百人巅峰”对决；全国运营中心在粤港澳大湾区正式成立；财商少年观世界&#45;&#45;冬、夏令营及海外留学启动</li>-->
<!--        <li>2019年，线上平台运营，“财商少年”系列课程成功获得校园网认证，第四届“财商少年”全国大赛招募令正式启动</li>-->
<!--      </ol>-->
<!--    </section>-->
<!--    <img src="~@/assets/img/main/bg_left@2x.png" alt="财商少年品牌成长历程" class="words-left">-->
<!--    <img src="~@/assets/img/main/bg_right@2x.png" alt="财商少年品牌成长历程" class="words-right">-->
<!--    <img src="~@/assets/img/main/bg_map@2x.png" class="bg">-->
    <img :src="imgPrefix + config.index_img_5_1.value[0].imgUrl">
  </div>
</template>

<script>
  import dynamicConfigMixin from "@/mixins/dynamicConfigMixin";

  export default {
    name: "MainGrow",
    mixins: [dynamicConfigMixin]
  }
</script>

<style lang="scss" scoped>
  .grow-up {
    width: 100%;
    position: relative;
    img {
      display: block;
      width: 100%;
    }
  }

  /**
  // 隐藏的文字,用于seo
  .section {
    position: absolute;
    text-indent: -999px;
  }

  .words-left {
    position: absolute;
    left: 15%;
    top: 50%;
    transform: translate(-15%, -50%);
    width: 33.2%;
  }

  .bg {
    position: absolute;
    z-index: 2;
    width: 100%;
  }

  .words-right {
    position: absolute;
    top: 0;
    right: 0;
    width: 47.5%;
  }
  **/
</style>