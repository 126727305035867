<template>
  <div class="tutors" v-if="config && config.index_show_7.value">
    <title-comp title-cn="导师团队" title-en="Tutors Team" color="write"/>
    <!-- 三个导师 -->
    <ul class="tutor3">
      <li class="tutor3-item">
<!--        <img src="@/assets/img/main/fujinyong.jpg" alt="财商导师符金泳" class="tutor3-face">-->
<!--        <img src="@/assets/img/main/fujinyong@2x.png" alt="财商导师符金泳介绍" class="tutor3-intro">-->
        <img :src="imgPrefix + config.index_img_7_1.value[0].imgUrl" class="tutor3-face">
        <img :src="imgPrefix + config.index_img_7_4.value[0].imgUrl" class="tutor3-intro">
      </li>
      <li class="tutor3-item">
<!--        <img src="@/assets/img/main/yebaiqiang.jpg" alt="财商导师叶柏强" class="tutor3-face">-->
<!--        <img src="@/assets/img/main/yebaiqiang@2x.png" alt="财商导师叶柏强介绍" class="tutor3-intro">-->
        <img :src="imgPrefix + config.index_img_7_2.value[0].imgUrl" class="tutor3-face">
        <img :src="imgPrefix + config.index_img_7_5.value[0].imgUrl" class="tutor3-intro">
      </li>
      <li class="tutor3-item">
<!--        <img src="@/assets/img/main/chenjieyuan.jpg" alt="财商导师陈洁源" class="tutor3-face">-->
<!--        <img src="@/assets/img/main/chenjieyuan@2x.png" alt="财商导师陈洁源介绍" class="tutor3-intro">-->
        <img :src="imgPrefix + config.index_img_7_3.value[0].imgUrl" class="tutor3-face">
        <img :src="imgPrefix + config.index_img_7_6.value[0].imgUrl" class="tutor3-intro">
      </li>
    </ul>

    <!--点状装饰-->
    <div class="points">
      <img src="@/assets/img/main/bg_circle_left@2x.png" class="points-left">
      <img src="@/assets/img/main/bg_circle_right@2x.png" class="points-right">
    </div>

    <!-- 6行导师 -->
    <ul class="teachers">
      <li v-for="(teacher, index) in config.index_teachers"
          :key="index"
          class="teacher"
          :class="{'prevent': !teacher.name}">
        <!-- 导师头像 -->
        <img :src="imgPrefix + teacher.avatar.imgUrl"
             class="teacher-face">
        <!-- 遮罩层 -->
        <div :class="{'teacher-mask': teacher.name}">
          <p>{{teacher.name}}</p>
          <p>{{teacher.area}}</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  // import teachers from "./teachers.js";
  import TitleComp from "./TitleComp";
  import dynamicConfigMixin from "@/mixins/dynamicConfigMixin";

  export default {
    name: "MainTutors",
    components: {
      TitleComp
    },
    mixins: [dynamicConfigMixin],
    // data() {
    //   return {
    //     teachers: teachers
    //   }
    // },

  }
</script>

<style lang="scss" scoped>
  .tutors {
    height: calc(64vw + 795px);
    min-height: 1915px;
    position: relative;
    overflow: hidden;
    background: url("~@/assets/img/main/index-tutors-bg.png") no-repeat;
    background-size: 100%;
  }

  .title-comp {
    position: absolute;
    top: 100px;
  }

  // 三个导师：ul
  .tutor3 {
    position: relative;
    width: 100%;
    margin-top: 300px;
    // 此处引起外边缘合并
    padding-top: 15.6%;
    display: flex;
    justify-content: space-around;
  }

  // 每个导师：li
  .tutor3-item {
    position: relative;
    width: 100%;
    // 导师照片
    .tutor3-face {
      border-radius: 10px;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 60%;
    }

    // 导师介绍
    .tutor3-intro {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      width: 50%;
      top: 300px;
    }

    // 第二个导师图片较大
    &:nth-child(2) .tutor3-face {
      /*width: 430px;*/
      width: 67%;
    }
  }

  // 六行导师:ul
  .teachers {
    width: calc(100% - 600px);
    min-width: 1250px;
    max-width: 1400px;
    display: flex;
    flex-wrap: wrap;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
  }

  // 每个导师:li
  .teacher {
    // 高度是125px
    flex: 10%;
    position: relative;
    cursor: pointer;
    margin-bottom: 10px;
    // 透视效果
    perspective: 1000;

    // 显示遮罩层
    &:hover {
      .teacher-face {
        transform: rotateY(-180deg);
      }

      .teacher-mask {
        transform: rotateY(-360deg);
      }
    }
  }

  // 导师头像:img
  .teacher-face {
    position: relative;
    display: block;
    width: 120px;
    transition: transform 1s;
    backface-visibility: hidden;
    // 没有这个属性翻过来就会显示人像
    opacity: 0.99;
  }

  // 导师遮罩层
  .teacher-mask {
    width: 120px;
    height: 120px;
    position: absolute;
    top: 0;
    left: 0;
    /*background: rgba(0, 0, 0, 0.5);*/
    // 反面颜色
    background: #0555a6;

    p {
      text-align: center;
      font-size: 20px;
      color: #fff;
      position: relative;
      top: 30%;
    }

    // 默认不显示
    transition: transform 1s;
    transform: rotateY(-180deg);
    backface-visibility: hidden;
  }

  // 阻止没有人像的色块旋转
  .prevent {
    &:hover {
      .teacher-face {
        transform: rotateY(0deg) !important;
      }
    }
  }

  .points {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
  }

  .points-left {
    position: absolute;
    width: 14%;
    left: 0;
    top: 50%;
    z-index: -1;
  }

  .points-right {
    position: absolute;
    right: 0;
    top: 70%;
  }
</style>
