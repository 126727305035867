<template>
  <main class="home">
    <main-banner/>
    <main-winner/>
    <main-college/>
    <main-grow/>
    <main-curriculum/>
    <main-tutors/>
    <main-news/>
    <main-partner/>
  </main>
</template>

<script>
  // 引入各个模块
  import MainBanner from "./childComp/MainBanner";
  import MainCollege from "./childComp/MainCollege";
  import MainGrow from "./childComp/MainGrow"
  import MainCurriculum from "./childComp/MainCurriculum";
  import MainTutors from "./childComp/MainTutors";
  import MainNews from "./childComp/MainNews";
  import MainWinner from "@/views/main/childComp/MainWinner";
  import MainPartner from "@/views/main/childComp/MainPartner";

  export default {
    // pc端首页
    name: "Main",
    // seo优化标签
    metaInfo: {
      title: '财商少年',
      meta: [
        {
          name: 'keyWords',
          content: '中国财商少年,财商少年, 财商, 少儿财商,财商教育,财商网,理财教育,全国现金流游戏大赛，乐享无限，中国财商少年app，财商导师'
        },
        {
          name: 'description',
          content: '中国财商少年，是广东财商少年信息科技有限公司旗下品牌，我们长期从事财商教育行业，有着专业的导师团队和丰富的教学经验，每年举办全国现金流游戏大赛，引领青少年与家庭财商发展，培养优秀财商导师。'
        }
      ]
    },
    components: {
      MainBanner,
      MainCollege,
      MainGrow,
      MainCurriculum,
      MainTutors,
      MainNews,
      MainWinner,
      MainPartner
    },
  }
</script>

<style lang="scss">
  .home p {
    margin: 0;
  }

</style>
