export default {
  data() {
    return {
      config: undefined,
      // 引用图片的前缀
      imgPrefix: 'https://oa.fqchildren.com/apis'
    }
  },
  created() {
    // 获取动态配置
    this.$axios.getConfig({
      version: 1
    }).then(res => {
      this.config = JSON.parse(res.data.content)
    })
  }
}