/**
 * 自动化更新新闻的前提:
 * 1. 能够爬取微信文章列表, 且首段文字能够表达文章的中心意思
 * 2. 或有人能够录入数据到后台
 * 新闻带图的前提:
 * 文章里面的首图必须是有意义的且能概况文章的
 */
// 主新闻
const mainNew = {
    title: '【签约仪式】孕育一流财商导师，共建财商教育生态圈——财商少年与广财大MBA学院校企合作签约仪式',
    text: '在假期，孩子的作息时间大多不规律，晚上不睡，早上不起是常态，可是开学就不能这样了。父母要循序渐进地调整孩子的作息时间，让孩子尽快适应学校的常规作息时间，早睡早起。',
    href: 'https://mp.weixin.qq.com/s/ehtbHl-Lx07W3nlafuQ9vw',
    year: '2019',
    date: '12-06',
    // pc端用的新闻主图
    img: require('@/assets/img/main/news1206.webp'),
}

// 副新闻
const subNews = [
  {
    title: '一份最好的儿童节礼物，请查收',
    text: '儿童节临近，不少家长朋友都想着给孩子准备一份特别的礼物，父母能给孩子很多东西，而教育是最好的礼物。',
    href: 'https://mp.weixin.qq.com/s/BjhU8xp5jy3oN0HaKRLVSA',
    year: '2020',
    date: '05-29',
  },
  {
    title: '校企合作 | 深化校企合作 促进校企共赢——广轻工外语学院校企合作交流考察活动',
    text: '2020年5月8日，广东轻工职业技术学院受邀走进财商少年，开展深度交流并做考察，探讨双方进一步合作战略及方向。广东轻工职业技术学院外语学院副书记兼副院长闫卫锋、TAFE教研室主任黎云莺、资深教师孙花云参加了此次交流考察活动。',
    href: 'https://mp.weixin.qq.com/s/LLBFvfu1sa-dB-EMl-ZXFw',
    year: '2020',
    date: '05-15',
  },
  {
    title: '母亲节丨爱在日常，才不寻常',
    text: '她记得你咿呀学语时的第一声“妈妈”, 记得你蹒跚学步时的可爱模样, 记得你爱吃和不爱吃的食物, 妈妈，母亲节快乐！',
    href: 'https://mp.weixin.qq.com/s/Nja1QkYHgXcCITqXyRJMgA',
    year: '2020',
    date: '05-08',
  },
  {
    title: '五一劳动节快乐！',
    text: '祝大家五一快乐！！！五一的小长假终于来临了，想必大家都期待已久，然而因为疫情的缘故，今年的五一注定比较特殊...',
    href: 'https://mp.weixin.qq.com/s/H5SZlZxFXPxs6nyE7AZ8Lg',
    year: '2020',
    date: '05-01',
  },
  {
    title: '少年财商素质教育导师课程第二期培训班毕业典礼 暨第三期培训班开班仪式，开始啦！',
    text: '广东财经大学MBA学院和"财商少年"强强联合推出【少年财商素质教育导师课程】第三期培训班, 这是双方专家团队"跨界"合作推出的线上+线下结合的精品课, 更有第二期毕业典礼等你来看',
    href: 'https://mp.weixin.qq.com/s/xYAapDTzXytNd8dmvIgTgQ',
    year: '2020',
    date: '04-24',
  },
  {
    title: '【线上直播】富爸爸富孩子，富过三代！',
    text: '本期财商少年公开课将由财商少年首席导师符金泳先生带大家进入财富的世界，教大家如何成为富爸妈富孩子，成就财富人生！！',
    href: 'https://mp.weixin.qq.com/s/EVznqWrWjqd7KADlh2Wd8Q',
    year: '2020',
    date: '04-17',
  },
  {
    title: '【财商动态】零花钱攻略训练营——开营啦！',
    text: '大人每天10分钟, 小孩每周30分钟, 少少的时间, 大大的收获, 全新一期《零花钱攻略训练营》即将于4月11日周六早上正式开营!!',
    href: 'https://mp.weixin.qq.com/s/D9AaNs6nZBSy6D5WSF7ABg',
    year: '2020',
    date: '04-10',
  },
  {
    title: '【财商动态】缤纷四月，财商多多填色征集活动',
    text: '财商少年吉祥物多多已经跟大家见面了, 请各位小伙伴一起来帮多多天上缤纷的色彩! 获赞最多的前三位可以获得包括豪华画笔套装等精美奖品哦!',
    href: 'https://mp.weixin.qq.com/s/XqOqVXYHML5opsb0tnixMQ',
    year: '2020',
    date: '04-03',
  },
  {
    title: '【财商导师】"少年财商素质教育导师"线上课堂即将开放！！',
    text: '经历两个多月的疫情隔离, 想给自己的知识增值进修却又苦于停工停课无从学习? 少年财商素质教育导师班线上课程计划将于三月底正式上线!',
    href: 'https://mp.weixin.qq.com/s/9IE2b8mVlhwicFJ_U_i03Q',
    year: '2020',
    date: '03-20',
  },
  {
    title: '【公益活动】爱心征集结果公布',
    text: '为了支持抗击新冠肺炎疫情, 为前线工作人员及患者打气, 财商少年爱心作品征集活动凝聚了大家的力量, 为疫情加油, 活动评选作品公布!',
    href: 'https://mp.weixin.qq.com/s/pNdNOm4BGRdjfUbzD0cJ2w',
    year: '2020',
    date: '03-06',
  },
  {
    title: '【公益活动】财商少年爱心征集令',
    text: '全国人民众志成城, 为打赢这场疫情防控攻坚战, 竭尽全力',
    href: 'https://mp.weixin.qq.com/s/LrqyimG1slkWyRBOC4manw',
    year: '2020',
    date: '02-28',
  },
  {
    title: '【公益课堂】如何与孩子进行有效沟通',
    text: '陪伴孩子轻松度过成长的烦恼，同时在家庭中引入孩子的财商启蒙，培养孩子正确看待世界的眼光',
    href: 'https://mp.weixin.qq.com/s/n8xveTP8N3vSYDZmZPHFQw',
    year: '2020',
    date: '02-21',
  },
  {
    title: '【财商动态】"压岁钱攻略"线上课程，正式上线啦！',
    text: '岁末年初是财商教育的最佳时机, 突遇疫情孩子的财商教育受阻怎么解决？ 财商少年为您的家庭财商教育保驾护航！',
    href: 'https://mp.weixin.qq.com/s/ajMvW7iqpW3DuRCi88o86Q',
    year: '2020',
    date: '02-14',
  },
  {
    title: '【预防手册】面对疫情高峰期的预防措施',
    text: '近日新型冠状病毒感染的肺炎疫情来势汹汹，但随着春节假期临近尾声，不少企业也即将复工，这就要面临着对新型冠状病毒的防控和宣传培训难题',
    href: 'https://mp.weixin.qq.com/s/r4A1pD8KcF2Ri-9_zXjirA',
    year: '2020',
    date: '02-07',
  },
  {
    title: '【辞旧迎新】百业俱兴，欣欣向荣',
    text: '正月初七，传说这天是人类的诞辰日，即人的生日，民间曾把这天叫做“人日”...',
    href: 'https://mp.weixin.qq.com/s/BDkwCXCe8irXNiRAV1mwnw',
    year: '2020',
    date: '01-31',
  },
  {
    title: '【财商少年】祝您新年快乐！',
    text: '中国拜年之风，汉代已有。唐宋之后十分盛行，有些不必亲身前往的，可用名帖投贺。',
    href: 'https://mp.weixin.qq.com/s/HCPJBsU2pBQNa5Rzry8zTA',
    year: '2020',
    date: '01-24',
  },
  {
      title: '【财商导师】第二期少年财商素质教育导师课程培训班顺利开班',
      text: '首期广财导师班成功开展后，紧急着很快就迎来了第二期，也是2020年度得首次广财导师班。',
      href: 'https://mp.weixin.qq.com/s/n1MsHgLV6S_VJa1QOIVLWw',
      year: '2020',
      date: '01-17',
  },
  {
      title: '【财商动态】少年财商素质教育导师课程2019年培训班颁授仪式暨2020年培训班开班仪式',
      text: '2020年1月4日，少年财商素质教育导师课程2019年培训班颁授仪式暨2020年培训班开班仪式在广东财经大学北三103室正式举行。',
      href: 'https://mp.weixin.qq.com/s/4wtV_YAfn0L5XgGyCWd-Ug',
      year: '2020',
      date: '01-10',
  },
  {
      title: '【欢庆元旦】一元复始，九州同庆',
      text: '在2020年的新年元旦来临之际, 在这里向一直支持我们的朋友们道一声新年好, 感谢您过去的支持, 未来仍然期待您对我们继续鼓励。',
      href: 'https://mp.weixin.qq.com/s/u2p-T9i4ZpJL0XU6YSvb8w',
      year: '2019',
      date: '12-31',
  },
  {
      title: '【财商动态】数说澳门回归20周年',
      text: '回归祖国20年，澳门始终坚守“一国”之本、善用“两制”之利，澳门的经济、民生等各项事业跨越式发展，称谓全球经济增长最快的地区之一。',
      href: 'https://mp.weixin.qq.com/s/q-GZFvTZA978oivRtjGBuw',
      year: '2019',
      date: '12-20',
  },
]

export {mainNew, subNews}
