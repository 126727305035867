export default class TimeFormatter {
  constructor(timeStamp) {
    this.date = new Date(timeStamp)
  }
  addZero(number) {
    if(number < 10) {
      return '0' + number
    }
    else return number
  }
  get year() {
    return this.addZero(this.date.getFullYear())
  }
  get month() {
    return this.addZero(this.date.getMonth() + 1)
  }
  get day() {
    return this.addZero(this.date.getDate())
  }
  get hour() {
    return this.addZero(this.date.getHours())
  }
  get minute() {
    return this.addZero(this.date.getMinutes())
  }
  getMacroTime() {
    if(!this.date.getTime()) {
      return ''
    }
    else return `${this.year}-${this.month}-${this.day}`
  }
}
