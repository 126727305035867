<template>
  <div class="partner" v-if="config && config.index_show_8.value">
    <title-comp title-cn="合作机构" title-en="Partner"/>
    <div class="card-container">
      <div class="card">
<!--        <img src="../../../assets/img/main/partner1.png" alt="国际专业财富管理协会">-->
<!--        <p>国际专业财富管理协会总部位于国际金融中心香港，是由多名大中华区域财富管理资深专家组成，专业从事财富管理理论研究和应用研究的国际化非盈利机构，-->
<!--        致力于推动财富管理领域的国际化教育，并推广国际适用的财富管理职业知识体系和认证标准。为各国家和地区培养现代金融领域管理所必须的专业理财、-->
<!--          风险管理、投资管理、资产管理等方面的中高级专业人才。-->
<!--        </p>-->
        <img :src="imgPrefix + config.index_img_8_1.value[0].imgUrl">
        <p>{{config.index_text_8_1.value}}</p>
      </div>

      <div class="card">
<!--        <img src="../../../assets/img/main/partner2.png" alt="广东财经大学">-->
<!--        <p>广东财经大学是广东和华南地区重要的经、管、法人人才培养、科学研究和社会服务基地，是广东省内唯一具有硕士授予权的财经类院校，拥有华南地区-->
<!--          高校最齐全的财经类学科专业体系，在华南地区高校与全国财经、政法类院校中具有较高知名度和行业影响力。-->
<!--        </p>-->
        <img :src="imgPrefix + config.index_img_8_2.value[0].imgUrl">
        <p>{{config.index_text_8_2.value}}</p>
      </div>

      <div class="card">
<!--        <img src="../../../assets/img/main/partner3.png" alt="广东轻工职业技术学院">-->
<!--        <p>广东轻工职业技术学院前身是创建于1933年的“广东省立第一职业学校”，2004年被教育部、建设部确认为国际建设类技能型紧缺人才培养试点高校。-->
<!--          2008年被列为国际示范性高等职业院校建设单位；2011年，已优秀等级通过教育部、财政部组织的示范验收；2016年，被列为广东省一流高职院校建设单位。-->
<!--        </p>-->
        <img :src="imgPrefix + config.index_img_8_3.value[0].imgUrl">
        <p>{{config.index_text_8_3.value}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import TitleComp from "./TitleComp";
  import dynamicConfigMixin from "@/mixins/dynamicConfigMixin";

  export default {
    name: "MainPartner",
    components: {
      TitleComp
    },
    mixins: [dynamicConfigMixin]
  }
</script>

<style lang="scss" scoped>
  .card-container {
    display: flex;
    width: 1280px;
    margin: 50px auto;
    justify-content: space-between;
  }
  .card {
    width: 420px;
    img {
      width: 420px;
      height: 190px;
      display: block;
    }
    p {
      background: #e6e6e6;
      padding: 35px 30px 0 30px;
      height: 210px;
      font-size: 14px;
    }
  }
</style>