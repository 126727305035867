<template>
<!--  财商学院 旋转滚动模块-->
  <div class="college" v-if="config && config.index_show_4.value">
<!--    <title-comp title-cn="财商学院" title-en="Financial Quotient"/>-->
<!--    动态获取的-->
    <title-comp :title-cn="config.index_text_4_1.value" :title-en="config.index_text_4_2.value"/>

    <div class="seo">
      <section v-for="item in seoData" :key="item.title">
        <h3>{{item.title}}</h3>
        <p>{{item.description}}</p>
      </section>
    </div>

<!--    <transition-group tag="div" class="message" name="message">-->
<!--      <img v-for="(img, index) in imgs" :src="img.src" :key="img.src" v-show="flag == index">-->
<!--    </transition-group>-->

<!--    动态获取的-->
    <transition-group tag="div" class="message" name="message">
      <img v-for="(img, index) in config.index_img_4_1.value" :src="'https://oa.fqchildren.com/apis' + img.imgUrl" :key="index + 'foo'" v-show="flag == index">
    </transition-group>

    <transition-group tag="div" class="rotate-img" name="img">
      <img v-for="(img, index) in rotateImgs" :src="img.src" :key="img.src" v-show="flag == index">
    </transition-group>

    <ul class="clicker">
      <li v-for="n in 3" :key="n"
          :class="{active: n-1 == flag}"
          @click="goto(n-1)"
          @mouseover="stop"
          @mouseleave="run"
      >
      </li>
    </ul>
  </div>
</template>

<script>
  import TitleComp from "./TitleComp";
  import dynamicConfigMixin from "@/mixins/dynamicConfigMixin";

  export default {
    name: "MainCollege",
    components: {
      TitleComp
    },
    mixins: [dynamicConfigMixin],
    data() {
      return {
        // 左侧文字图片路径
        imgs: [
          {src: require('@/assets/img/main/tutor@2x.png')},
          {src: require('@/assets/img/main/children@2x.png')},
          {src: require('@/assets/img/main/parents@2x.png')}
        ],
        // 轮转图片路径
        rotateImgs: [
          {src: require('@/assets/img/main/tutorcircle@2x.png')},
          {src: require('@/assets/img/main/childrencircle@2x.png')},
          {src: require('@/assets/img/main/parentscircle@2x.png')}
        ],
        flag: 0,
        interval: null,
        // 轮播时间
        waitTime: 3000,
        seoData: [
          {
            title: '财商导师学院',
            description: '中国财商少年拥有实战经验丰富的强大财商导师团队，形成了完备的导师培训体系，致力于培育更多优秀的少年财商导师。',
          },
          {
            title: '财商少年学堂',
            description: '财商少年学堂与家庭一起打造孩子终身学习成长平台，开发了一系列的家庭亲子理财课程与活动。',
          },
          {
            title: '父母学堂',
            description: '我们深知财商教育过程中，父母的重要性，财商少年打造了丰富的父母成长配套课程',
          },
        ]
      }
    },
    methods: {
      goto(flag) {
        this.flag = flag
      },
      run() {
        this.interval = setInterval(function () {
          if (this.flag === 2) {
            this.flag = 0
            return
          }
          this.flag++
        }.bind(this), this.waitTime)
      },
      stop() {
        clearInterval(this.interval)
      }
    },
    mounted() {
      this.run()
    },
  }
</script>

<style lang="scss" scoped>
    .title-comp {
      position: absolute;
      top: 100px;
    }
  .college {
    width: 100%;
    // 自适应
    padding-bottom: 47%;
    position: relative;
    background: url("~@/assets/img/main/bg_blue_left@2x.png") no-repeat;
    background-size: 34.3% auto;

    cursor: pointer;
  }

  .message {
    position: absolute;
    left: 18.5%;
    width: 30%;
    /*left: 355px;*/
    padding-top: 15.6%;
    /*top: 300px;*/
    top: 0;

    img {
      position: absolute;
      /*height: 100%;*/
    }
  }

  /* 过渡效果 */
  .message-enter {
    transform: translateX(100%)
    // opacity: 0;
  }

  .message-enter-to, .message-leave {
    transform: translateX(0);
    // opacity: 1;
  }

  .message-leave-active, .message-enter-active {
    transition: all 1.5s ease;
  }

  .message-leave-to {
    transform: translateX(-200%)
    // opacity: 0;
  }

  // 旋转图片
  .rotate-img {
    /*width: 751px;*/
    /*height: 749px;*/
    /*width: 39%;*/
    width: 39%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    right: 0;
    bottom: 0;

    img {
      width: 100%;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  // 过渡效果
  .img-enter {
    transform: rotate(90deg);
    transform-origin: 100% 100%;
  }

  .img-enter-to, .img-leave {
    transform: rotate(0deg);
    transform-origin: 100% 100%;
  }

  .img-leave-active, .img-enter-active {
    transition: transform 1.5s;
  }

  .img-leave-to {
    transform: rotate(-90deg);
    transform-origin: 100% 100%;
  }

  // 点击切换
  .clicker {
    position: absolute;
    /*top: 708px;*/
    /*left: 335px;*/
    top: 0;
    // 自适应
    margin-top: 35.5%;
    left: 18.5%;

    li {
      height: 6px;
      width: 40px;
      border-radius: 10px;
      background: #7fd0f3;
      float: left;
      margin-right: 25px;
      cursor: pointer;
      transition: width 1s;
    }
  }

  .active {
    background: #00a2e8 !important;
    width: 80px !important;
    transition: width 1s;
  }

  // 隐藏的seo
  .seo {
    position: absolute;
    top: 0;
    text-indent: -999px;
  }
</style>
