<template>
  <div class="curriculum" v-if="config && config.index_show_6.value">
<!--    <title-comp title-cn="四大课程体系" title-en="Four Major Curriculum Systems"/>-->
    <title-comp :title-cn="config.index_text_6_1.value" :title-en="config.index_text_6_2.value"/>

    <!-- 四个模块 -->
    <ul class="blocks clear-before">
<!--      <li v-for="(block, index) in blocks" :key="index"-->
<!--          @mouseover="currentIndex = index"-->
<!--          @mouseleave="currentIndex = -1"-->
<!--          class="block"-->
<!--      >-->
<!--        <div class="inner">-->
<!--          &lt;!&ndash; 课程logo &ndash;&gt;-->
<!--          <div class="img-holder">-->
<!--            <img :src="block.innerSrc">-->
<!--          </div>-->
<!--          <h3 class="block-title">《{{block.title}}》</h3>-->
<!--          <p class="block-subtitle">{{block.subtitle}}</p>-->
<!--          <p class="block-describe">{{block.people}}</p>-->
<!--          <p class="block-describe">{{block.introduce}}</p>-->
<!--        </div>-->
<!--      </li>-->

      <li @mouseover="currentIndex = 0"
          @mouseleave="currentIndex = -1"
          class="block">
        <div class="inner">
          <!-- 课程logo -->
          <div class="img-holder">
            <img :src="imgPrefix + config.index_img_6_1.value[0].imgUrl">
          </div>
          <h3 class="block-title">{{config.index_text_6_3.value}}</h3>
          <p class="block-subtitle">{{config.index_text_6_4.value}}</p>
          <p class="block-describe">{{config.index_text_6_5.value}}</p>
          <p class="block-describe">{{config.index_text_6_6.value}}</p>
        </div>
      </li>

      <li @mouseover="currentIndex = 1"
          @mouseleave="currentIndex = -1"
          class="block">
        <div class="inner">
          <!-- 课程logo -->
          <div class="img-holder">
            <img :src="imgPrefix + config.index_img_6_2.value[0].imgUrl">
          </div>
          <h3 class="block-title">{{config.index_text_6_7.value}}</h3>
          <p class="block-subtitle">{{config.index_text_6_8.value}}</p>
          <p class="block-describe">{{config.index_text_6_9.value}}</p>
          <p class="block-describe">{{config.index_text_6_10.value}}</p>
        </div>
      </li>

      <li @mouseover="currentIndex = 2"
          @mouseleave="currentIndex = -1"
          class="block">
        <div class="inner">
          <!-- 课程logo -->
          <div class="img-holder">
            <img :src="imgPrefix + config.index_img_6_3.value[0].imgUrl">
          </div>
          <h3 class="block-title">{{config.index_text_6_11.value}}</h3>
          <p class="block-subtitle">{{config.index_text_6_12.value}}</p>
          <p class="block-describe">{{config.index_text_6_13.value}}</p>
          <p class="block-describe">{{config.index_text_6_14.value}}</p>
        </div>
      </li>

      <li @mouseover="currentIndex = 3"
          @mouseleave="currentIndex = -1"
          class="block">
        <div class="inner">
          <!-- 课程logo -->
          <div class="img-holder">
            <img :src="imgPrefix + config.index_img_6_4.value[0].imgUrl">
          </div>
          <h3 class="block-title">{{config.index_text_6_15.value}}</h3>
          <p class="block-subtitle">{{config.index_text_6_16.value}}</p>
          <p class="block-describe">{{config.index_text_6_17.value}}</p>
          <p class="block-describe">{{config.index_text_6_18.value}}</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import TitleComp from "./TitleComp";
  import dynamicConfigMixin from "@/mixins/dynamicConfigMixin";

  export default {
    name: "MainCurriculum",
    components: {
      TitleComp
    },
    mixins: [dynamicConfigMixin],
    data() {
      return {
        blocks: [
          {
            innerSrc: require('@/assets/img/main/icon_child@2x.png'),
            title: '少儿财商启蒙',
            subtitle: '系列课程',
            people: '适合人群：6-12岁儿童',
            introduce: '课程介绍：寓教于乐，从游戏中理解财商概念'
          },
          {
            innerSrc: require('@/assets/img/main/icon_ceo@2x.png'),
            title: '未来"CEO"',
            subtitle: '财商系列课程',
            people: '适合人群：6-12岁儿童',
            introduce: '课程介绍：系统学习，树立正确财商观念，培养理财习惯'
          },
          {
            innerSrc: require('@/assets/img/main/icon_family@2x.png'),
            title: '家庭理财规划课堂',
            subtitle: '财商系列课程',
            people: '适合人群：成人',
            introduce: '课程介绍：贴近生活，清晰家庭财务，科学理财，合理投资'
          },
          {
            innerSrc: require('@/assets/img/main/icon_people@2x.png'),
            title: '成人财商研修',
            subtitle: '财商系列课程',
            people: '适合人群：理财师，财商教育从业意向者',
            introduce: '课程介绍：连接国际金融理财学习平台，全方位掌握专业理财规划能力'
          }
        ],
        currentIndex: -1
      }
    },
  }
</script>

<style lang="scss" scoped>
  .title-comp {
    position: absolute;
    top: 100px;
  }
  .curriculum {
    width: 100%;
    height: 950px;
    position: relative;
  }

  // 四个课程模块: ul
  .blocks {
    position: relative;
    text-align: center;
    top: 343px;
    height: 440px;
    padding: 5px 0;
  }

  // 每一个课程模块：li
  .block {
    width: 300px;
    height: 430px;
    // 伪装一下
    margin-top: 15px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    margin-right: 25px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    transition: width 0.5s;

    // 红色方块
    &:nth-child(1) {
      background: #e40273;

      &:hover {
        background: url("~@/assets/img/main/bg_red_click@2x.png") no-repeat center;
        background-size: 405px;
      }
    }

    &:nth-child(2) {
      background: #ffe201;

      &:hover {
        background: url("~@/assets/img/main/bg_yellow_click@2x.png") no-repeat center;
        background-size: 405px;
      }
    }

    &:nth-child(3) {
      background: #96c61c;

      &:hover {
        background: url("~@/assets/img/main/bg_green_click@2x.png") no-repeat center;
        background-size: 405px;
      }
    }

    &:nth-child(4) {
      background: #03aceb;

      &:hover {
        background: url("~@/assets/img/main/bg_blue_click@2x.png") no-repeat center;
        background-size: 405px;
      }
    }
  }

  .block:nth-child(1) {
    background: #e40273;
  }

  // 鼠标放置效果
  .block:hover {
    // 背景宽度增加30, 容纳三角形
    /*width: 390px;*/
    width: 420px;
    height: 460px;
    margin-top: 0;
    margin-bottom: -15px;

    .inner {
      // 整体上移
      top: 60px;
      // 图片缩小
      .img-holder {
        width: 85px;
        height: 85px;
      }

      // 文字出现
      .block-describe {
        display: block;
        margin-top: 30px;
      }
    }
  }

  .inner {
    width: 100%;
    position: absolute;
    top: 90px;
    transition: top 0.5s;
    left: 50%;
    transform: translateX(-50%);
    color: #ffffff;

    // logo
    .img-holder {
      width: 160px;
      height: 160px;
      margin: 0 auto 15px;
      transition: width 0.5s, height 0.5s;

      img {
        width: 100%;
        transition: width 0.5s, height 0.5s;
      }
    }


    .block-title {
      font-size: 30px;
      font-weight: normal;
    }

    .block-subtitle {
      font-size: 24px;
    }

    .block-describe {
      display: block;
      text-align: left;
      margin: 200px 30px 0 35px;
      transition: margin-top 0.5s;
      font-size: 16px;
      line-height: 26px;
      /*white-space: nowrap;*/
    }
  }
</style>