<template>
  <div class="news">
    <title-comp title-cn="最新动态" title-en="News Update"/>
    <div class="news-main" v-if="subNews[0]">
      <div class="news-left">
        <!--主图-->
        <a :href="subNews[0].link">
          <img :src="subNews[0].image" :alt="mainNew.title" class="news-img">
        </a>
        <!--左侧文章-->
        <div class="news-item-main clearfix">
          <h3 class="news-title news-title-main" :title="subNews[0].title">
            <a :href="subNews[0].link" target="_blank">{{subNews[0].title}}</a>
          </h3>
          <div class="time">
            <p class="year">{{subNews[0].relTime.substring(0, 4)}}</p>
            <p class="date">{{subNews[0].relTime.substring(5,10)}}</p>
          </div>
          <span class="line"></span>
          <div class="news-text-main">
            <p>{{mainNew.text}}</p>
          </div>
        </div>
      </div>

      <!--右侧文章-->
      <div class="news-right" v-if="subNews[0]">
        <ul class="sub-news-container">
          <li v-for="item in subNews.slice(1)"
              :key="item.title"
              class="news-item">
            <a :href="item.link" target="_blank">
              <div class="time">
                <p class="year">{{item.relTime.slice(0, 4)}}</p>
                <p class="date">{{item.relTime.slice(5)}}</p>
              </div>
              <span class="line"></span>
              <!--次要文章-->
              <div class="article">
                <h3 class="news-title" :title="item.title" :class="{indent: !/^【/.test(item.title)}">
                  {{item.title}}
                </h3>
                <p>{{item.depict}}</p>
              </div>
            </a>
          </li>
        </ul>

<!--        <div class="more">-->
<!--          <a href="/" @click.prevent="getMoreNews" title="点击加载更多">More >></a>-->
<!--        </div>-->
      </div>
    </div>
  </div>
</template>

<script>
  import TitleComp from "./TitleComp";
  // 每周更新新闻
  import {mainNew} from "@/assets/js/news";
  import TimeFormatter from "@/utils/timeFormatter"

  export default {
    name: "MainNews",
    components: {
      TitleComp
    },
    data() {
      return {
        mainNew: mainNew,
        subNews: []
      }
    },
    created() {
      this.$axios.news.getNews({
        sort: 'relTime',
        types: 'cssn',
        page: 0,
        limit: 10
      }).then(res => {
        this.subNews = res.data.list.map(item => {
          item.relTime = new TimeFormatter(item.relTime).getMacroTime()
          return item
        })
      })
    },
    methods: {
      // // 每次增加4个新闻
      // getMoreNews() {
      //   let currentLength = this.subNews.length
      //   let totalLength = subNews.length
      //   let remain = totalLength - currentLength
      //   if (currentLength < totalLength) {
      //     if (remain >= 4) {
      //       this.subNews = subNews.slice(0, currentLength + 4)
      //     } else (this.subNews = subNews)
      //   } else return
      // }
    }
  }
</script>

<style lang="scss" scoped>
  .news {
    // 自适应
    padding-bottom: 47%;
    position: relative;
  }
  .title-comp {
    position: absolute;
    top: 100px;
  }
  // 主要内容
  .news-main {
    // 动态获取新闻后, 只能用默认字体
    font-family: "Helvetica Neue",Helvetica,"PingFang SC","Hiragino Sans GB","Microsoft YaHei","微软雅黑",Arial,sans-serif;;
    position: absolute;
    width: 1400px;
    left: 50%;
    transform: translateX(-50%);
    top: 280px;
    // 左侧内容
    .news-left {
      float: left;
      // 与图片同宽
      width: 660px;
    }

    // 右侧内容
    .news-right {
      float: left;
      margin-left: 48px;
    }
  }

  .sub-news-container {
    height: 460px;
    overflow-y: auto;
  }

  // 右侧每一个副文章: h=120px
  .news-item {
    width: 660px;
    height: 100px;
    margin-top: 20px;

    &:first-child {
      margin: 0;
    }

    box-sizing: border-box;
    border-bottom: 1px solid #d9d9d9;
  }

  // 主图
  .news-img {
    float: right;
    display: block;
    width: 610px;
  }

  // 主文章
  .news-item-main {
    width: 610px;
    float: right;
    margin-top: 26px;
  }

  // 全部文章的标题
  .news-title {
    width: 100%;
    font-size: 24px;
    font-weight: normal;
    // 美化中文中括号
    // margin-left: -10px;
    // 标题只占用一行
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    a {
      color: #333333;
    }
  }

  // 时间
  .time {
    float: left;
    height: 70px;
    width: 100px;
    display: inline-block;
    color: #005dae;
  }

  .year {
    font-size: 40px;
    line-height: 40px;
  }

  .date {
    font-size: 22px;
  }

  // 竖线
  .line {
    float: left;
    width: 2px;
    height: 40px;
    background: #005dae;
    margin-top: 15px;
    margin-left: 9px;
  }

  // 主文章正文
  .news-text-main {
    float: left;
    height: 70px;
    width: 490px;
    margin-left: 13px;
    margin-top: 10px;
    font-size: 16px;
    color: #333333;
    // 不加会溢出到下一行
    margin-right: -10px;

    p {
      // 限制字数
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

  }

  // 右侧文章,包含标题和正文
  // 标题字数过多样式会崩坏
  .article {
    float: left;
    margin-left: 13px;
    width: 530px;

    p {
      margin-top: 3px;
      // 限制字数
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  // 更多
  .more {
    margin-top: 30px;
    float: right;

    a {
      font-weight: bold;
      font-size: 24px;
      color: #333333;
    }
  }

  // 不以【开头的标题需要indent
  .indent {
    text-indent: 12px;
  }
</style>
