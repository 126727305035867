<template>
  <swiper :options="swiperOption" ref="mySwiper" class="swiper-container" v-if="config">
    <swiper-slide v-for="(img, index) in config.index_img_1_1.value" :key="index">
      <a target="_blank">
        <img :src="imgPrefix + img.imgUrl" class="banner-img">
      </a>
    </swiper-slide>

    <div class="swiper-pagination" slot="pagination"></div>

    <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
  </swiper>
</template>

<script>
  import 'swiper/dist/css/swiper.css'
  import {swiper, swiperSlide} from 'vue-awesome-swiper'
  import dynamicConfigMixin from "@/mixins/dynamicConfigMixin";

  export default {
    name: "MainBanner",
    components: {
      swiper,
      swiperSlide
    },
    mixins: [dynamicConfigMixin],
    data() {
      return {
        // swiper选项
        swiperOption: {
          pagination: {
            el: '.swiper-pagination',
            clickable: true
          },
          navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
          },
          loop: true,
          autoplay: true
        },
        // 轮播图数据
        imgData: [
          // {
          //   url: require('@/assets/img/main/导师班@2x.png'),
          //   title: '少年财商素质教育导师班'
          // },
          // {
          //   url: require('@/assets/img/main/零花钱训练营@2x.png'),
          //   title: '零花钱攻略训练营'
          // },
          // {
          //   url: require('@/assets/img/main/banner@2x.png'),
          //   title: '财商少年全国大赛，全国现金流大赛'
          // },
          // {
          //   url: require('@/assets/img/main/GCbanner@2x.png'),
          //   href: 'https://mp.weixin.qq.com/s/ehtbHl-Lx07W3nlafuQ9vw',
          //   title: '财商少年与广东财经大学，校企合作签约仪式'
          // },
        ]
      }
    },
  }
</script>

<style lang="scss" scoped>
  .banner-img {
    // 防止出现缝隙
    display: block;
    width: 100%;
  }

  ::v-deep .swiper-container {
    position: relative;
    width: 100%;
  }

  ::v-deep .swiper-button-prev {
    height: 100%;
    width: 200px;
    top: 0;
    left: 0;
    margin: 0;
    background-image: url("~@/assets/img/main/icon_left@2x.png");
    outline: none;

  }

  ::v-deep .swiper-button-next {
    height: 100%;
    width: 200px;
    top: 0;
    right: 0;
    margin: 0;
    background-image: url("~@/assets/img/main/icon_right@2x.png");
    outline: none;
  }

  ::v-deep .swiper-pagination-bullet {
    width: 20px;
    height: 10px;
    border-radius: 5px;
    transition: width 0.5s;
    background: #ffffff;
    opacity: 1;
  }

  ::v-deep .swiper-pagination-bullet-active {
    width: 40px;
    height: 10px;
    border-radius: 5px;
    background: #409eff;
  }
</style>
